import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, Settings, Trash2 } from "lucide-react";
import { Button } from "../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { communityStore } from "../stores/community-store";
import { authStore } from "../stores/auth-store";
import { BottomNav } from "./bottom-nav";
import { manageCommunityStore } from "../stores/manage-community.store";
import { DeleteCommunitySheet } from "./communities/delete-community-sheet";
import { manageEventStore } from "./events/stores/manage-event.store";
import CancelEventDialog from "./events/components/cancel-event-dialog";

interface LayoutProps {
  children: React.ReactNode;
  onBack?: () => void;
}

const Layout: React.FC<LayoutProps> = observer(({ children, onBack }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = authStore;
  const { deleteCommunity } = manageCommunityStore;
  const { cancelEvent } = manageEventStore;
  const [isDeleteSheetOpen, setIsDeleteSheetOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleBackClick = () => {
    if (location.pathname === "/c/new" && onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  const getHeaderInfo = () => {
    const path = location.pathname.slice(1);
    const pathParts = path.split("/");

    switch (true) {
      case path === "":
        return { show: false };
      case path === "join":
        return { show: true, title: "Login / Sign Up" };
      case path === "verify":
        return { show: true, title: "Verify your number" };
      case path === "onboarding-profile":
        return { show: true, title: " Let us get to know you" };
      case path === "about-us":
        return { show: true, title: "About Us" };
      case path === "explore":
        return { show: true };
      case path === "c/new":
        return { show: true, title: "Create a community" };
      case path === "me":
        return {
          show: true,
          title: "Profile",
          showSettingsIcon: true,
          onClick: () => navigate("settings"),
        };
      case path === "me/edit":
        return {
          show: true,
          title: "Edit Profile",
        };
      case path === "calendar":
        return { show: true, title: "Delhi NCR" };
      case path === "e/new":
        return { show: true, title: "Create New Event" };
      case path === "settings":
        return { show: true, title: "Settings" };
      case path === "talk-to-us":
        return { show: true, title: "Talk to Us" };
      case pathParts[1] === "manage":
        if (pathParts[2] === "admins") {
          return {
            show: true,
            title: "Admin details",
          };
        }
        return {
          show: true,
          title: "Manage Community",
          showDelete: true,
          onClickDelete: () => setIsDeleteSheetOpen(true),
        };
      case pathParts[0] === "e":
        if (pathParts[2] === "manage") {
          return {
            show: true,
            title: "Manage Event",
            showDelete: true,
            onClickDelete: () => setIsDialogOpen(true),
          };
        }
        return { show: false };
      case path === "search":
        return { show: false, title: "" };
      case pathParts[0] === communityStore.currentCommunityViewName:
        return { show: false };
      default:
        if (!["join", "register", "me", "settings"].includes(path)) {
          return { show: true, title: communityStore.currentCommunityViewName };
        }
        return { show: false, title: "" };
    }
  };

  const shouldShowBottomNav = () => {
    const noNavPaths = ["login", "verify", "onboarding-profile"];
    return !noNavPaths.includes(location.pathname.slice(1));
  };

  const headerInfo = getHeaderInfo();

  return (
    <div className="tw-flex tw-min-h-screen">
      <div className="tw-flex tw-flex-col tw-flex-grow tw-min-w-0">
        <DeleteCommunitySheet
          open={isDeleteSheetOpen}
          onOpenChange={() => setIsDeleteSheetOpen(false)}
          onDeleteClick={async () => {
            await deleteCommunity();
            navigate("/explore");
          }}
        />
        <CancelEventDialog
          open={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          onCancelEvent={() => {
            setIsDialogOpen(false);
            cancelEvent();
            navigate("/explore");
          }}
        />
        {headerInfo.show && (
          <header className="tw-flex tw-items-center tw-pt-4 tw-px-4 md:tw-p-6 tw-relative">
            <div className="tw-flex tw-items-center tw-w-full">
              <Button
                variant="secondary"
                size="icon"
                className="tw-mr-4 !tw-rounded-3xl"
                onClick={handleBackClick}
              >
                <ChevronLeft />
              </Button>
              <h1 className="tw-text-xl tw-font-bold tw-truncate tw-flex-grow tw-text-left">
                {headerInfo.title}
              </h1>
              <div className="tw-flex tw-items-center">
                {headerInfo.showSettingsIcon && (
                  <Button
                    variant="secondary"
                    size="icon"
                    className="tw-rounded-3xl tw-flex-shrink-0"
                    onClick={headerInfo.onClick}
                  >
                    <Settings size={24} />
                  </Button>
                )}
                {headerInfo.showDelete && (
                  <Button
                    variant="ghost"
                    size="icon"
                    className="tw-ml-2 tw-text-red-500"
                    onClick={headerInfo.onClickDelete}
                  >
                    <Trash2 size={24} />
                  </Button>
                )}
              </div>
            </div>
          </header>
        )}
        <main className="tw-flex-1 tw-overflow-y-auto tw-pb-16">
          {children}
        </main>
        {shouldShowBottomNav() && (
          <BottomNav profilePicture={user?.profilePicture} />
        )}
      </div>
    </div>
  );
});

export default Layout;
