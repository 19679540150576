import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn-components/tabs";
import { ManageCommunityForm } from "./manage-community";
import { Button } from "../../shadcn-components/button";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn-components/avatar";
import { Plus, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { manageCommunityStore } from "../../stores/manage-community.store";
import { getUserImage } from "../../utils/image-utils";
import { RemoveUserDialog } from "./delete-admin-confirm";
import { ManageAdmins } from "./manage-admins";

export const CommunityManagement = observer(() => {
  const { communityHandle } = useParams<{ communityHandle: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAdminName, setSelectedAdminName] = useState("");
  const [selectedAdminUserId, setSelectedAdminUserId] = useState("");
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const {
    adminsList,
    getAdmins,
    editCommunityData,
    isLoadingAdminsList,
    deleteAdmin,
    getManageFormData,
  } = manageCommunityStore;

  useEffect(() => {
    const fetch = async () => {
      if (communityHandle) {
        await getManageFormData(communityHandle);
      }
      if (editCommunityData?.id) {
        await getAdmins(editCommunityData?.id);
      }
    };
    fetch();
  }, [communityHandle, editCommunityData?.id, getAdmins, getManageFormData]);

  const handleDeleteAdmin = async (id: string) => {
    await deleteAdmin(id);
    await getAdmins(editCommunityData?.id);
  };

  const handleAddAdmin = () => {
    setIsSheetOpen(true);
  };

  const isAdminTab = location.state?.tab === "admin";
  const activeTab = isAdminTab ? "admin" : "display";

  const handleTabChange = (value: string) => {
    if (value === "admin") {
      navigate(`/${communityHandle}/manage`, { state: { tab: "admin" } });
    } else {
      navigate(`/${communityHandle}/manage`);
    }
  };

  const styles = {
    tabsList:
      "tw-grid tw-w-full tw-grid-cols-2 tw-bg-transparent tw-relative tw-p-2 after:tw-absolute after:tw-bottom-[-1px] after:tw-left-0 after:tw-right-0 after:tw-h-[1px] after:tw-bg-[#E7E7E7] after:tw-z-0",
    tabTrigger:
      "tw-border-0 tw-text-base !tw-bg-transparent !tw-text-gray-500 hover:!tw-text-gray-700 tw-relative data-[state=active]:!tw-text-blue-500 after:tw-content-[''] after:tw-absolute after:tw-bottom-[-1px] after:tw-left-0 after:tw-w-full after:tw-h-[2px] after:tw-bg-blue-500 after:tw-opacity-0 data-[state=active]:after:!tw-opacity-100 after:tw-z-10",
    addButton:
      "tw-flex tw-items-center tw-justify-center tw-gap-2 tw-h-10 tw-px-4 tw-py-2 tw-border-[#13131533] tw-font-semibold tw-text-base",
    adminCard: "tw-flex tw-items-center tw-justify-between",
    adminInfo: "tw-flex tw-items-center tw-space-x-3",
    avatar: "tw-h-10 tw-w-10",
    deleteButton: "tw-text-red-500",
  };

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen">
      <RemoveUserDialog
        userName={selectedAdminName}
        onRemove={() => handleDeleteAdmin(selectedAdminUserId)}
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
      />
      <ManageAdmins open={isSheetOpen} onOpenChange={setIsSheetOpen} />
      <Tabs
        value={activeTab}
        onValueChange={handleTabChange}
        className="tw-w-full"
      >
        <TabsList className={styles.tabsList}>
          <TabsTrigger value="display" className={styles.tabTrigger}>
            Display
          </TabsTrigger>
          <TabsTrigger value="admin" className={styles.tabTrigger}>
            Admin
          </TabsTrigger>
        </TabsList>

        <TabsContent value="display">
          <ManageCommunityForm />
        </TabsContent>

        <TabsContent value="admin">
          <div className="tw-p-4 tw-space-y-6 tw-max-w-md tw-mx-auto">
            {isLoadingAdminsList ? (
              <div className="tw-flex tw-justify-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="tw-space-y-4">
                {adminsList.map((admin) => (
                  <div key={admin.userId} className={styles.adminCard}>
                    <div className={styles.adminInfo}>
                      <Avatar className={styles.avatar}>
                        <AvatarImage src={getUserImage(admin.profilePicture)} />
                        <AvatarFallback>
                          {admin.accountName.charAt(0)}
                        </AvatarFallback>
                      </Avatar>
                      <div className="tw-flex tw-flex-col">
                        <span className="tw-font-medium">
                          {admin.accountName}
                        </span>
                      </div>
                    </div>
                    {!admin.isOwner && (
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => {
                          setIsDialogOpen(true);
                          setSelectedAdminName(admin.accountName);
                          setSelectedAdminUserId(admin.userId);
                        }}
                        className={styles.deleteButton}
                      >
                        <Trash2 className="tw-h-4 tw-w-4" />
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            )}

            <Button
              variant="outline"
              onClick={handleAddAdmin}
              className={styles.addButton}
            >
              <Plus size={20} />
              Add Admin
            </Button>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
});
