import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { EventData } from "./event-store";
import { Community } from "@/components/communities/api/community-api";
import { EventFormValues } from "./create-event-store";
import {
  eventApi,
  EventAttendee,
  EventGuestsResponse,
} from "../api/address/event-api";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface UpdateStatus {
  field: string;
  status: "saving" | "success" | "error";
  message?: string;
}

class ManageEventStore {
  @observable editEventData: EventData | null = null;
  @observable isLoading = false;
  @observable updateStatus: UpdateStatus | null = null;
  @observable isSubmitting = false;
  @observable adminCommunities: Community[] = [];
  @observable isLoadingCommunities = false;
  @observable isLoadingGuests = false;
  @observable checkInGuests: EventAttendee[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  getEventData = async (eventId: string) => {
    this.isLoading = true;
    try {
      const response = await axios.get(`${apiBaseUrl}/events/${eventId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      runInAction(() => {
        this.editEventData = response.data.data;
      });
    } catch (error) {
      console.error("Error fetching event data:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  uploadEventBanner = async (file: File): Promise<string> => {
    try {
      // Upload to S3
      const key = await eventApi.uploadImageWithPresignedUrl(file);
      if (!key) {
        throw new Error("error uploading");
      }
      return key;
    } catch (error) {
      console.error("Error uploading banner:", error);
      throw new Error("Failed to upload banner");
    }
  };

  @action
  updateEvent = async (values: Partial<EventFormValues>, bannerFile?: File) => {
    this.isSubmitting = true;
    try {
      const updateData: Partial<EventFormValues> = { ...values };

      // Handle banner upload if provided
      if (bannerFile) {
        const bannerKey = await this.uploadEventBanner(bannerFile);
        updateData.logo = bannerKey;
      }

      // Update event data
      const response = await axios.patch(
        `${apiBaseUrl}/events/${this.editEventData?.eventId}`,
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      runInAction(() => {
        this.editEventData = response.data;
      });

      return response.data;
    } catch (error) {
      console.error("Error updating event:", error);
      throw error;
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  };

  @action
  getAdminCommunities = async () => {
    try {
      this.isLoadingCommunities = true;
      const response = await axios.get(
        `${apiBaseUrl}/communities?isAdmin=true`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      runInAction(() => {
        this.adminCommunities = response.data.items;
      });
    } catch (error) {
      console.error("Failed to fetch communities:", error);
      runInAction(() => {
        this.adminCommunities = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingCommunities = false;
      });
    }
  };

  @action
  getEventGuests = async (id: string) => {
    runInAction(() => {
      this.isLoadingGuests = true;
    });

    try {
      const response: EventGuestsResponse = await eventApi.getEventGuests(id);
      runInAction(() => {
        // Extract the attendees array from the response
        this.checkInGuests = response.attendees || [];
      });
    } catch (error) {
      console.error("Failed to fetch guests:", error);
      runInAction(() => {
        this.checkInGuests = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingGuests = false;
      });
    }
  };

  @action
  checkInGuest = async (eventId: string, userId: string) => {
    try {
      await eventApi.checkInUser(eventId, userId);
    } catch (error) {
      console.error("Failed to fetch guests:", error);
    }
  };

  @action
  cancelEvent = async () => {
    if (this.editEventData && this.editEventData.eventId) {
      try {
        await eventApi.cancelEvent(this.editEventData.eventId);
      } catch (error) {
        console.error("Failed to cancel event:", error);
      }
    }
  };
}

export const manageEventStore = new ManageEventStore();
