// manage-community.store.ts
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import axios from "axios";
import { ManageCommunityFormValues } from "@/components/communities/manage-community.schema";
import { Community } from "./community-store";
import { communityApi } from "../components/communities/api/community-api";
import { apiClient } from "./api-client";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface UpdateStatus {
  field: keyof ManageCommunityFormValues;
  status: "saving" | "success" | "error";
  message?: string;
}

class ManageCommunityStore {
  @observable editCommunityData: Community | null = null;
  @observable isLoading = false;
  @observable updateStatus: UpdateStatus | null | undefined;
  @observable isAdminsViewLoading = false;
  @observable isAddingAdmin = false;
  @observable isRemovingAdmin = false;
  @observable isSearchingUser = false;
  @observable searchResults: SearchResult[] = [];
  @observable isLoadingAdminsList = false;
  @observable adminsList: AdminUser[] = [];
  @observable isUsernameAvailable: boolean | null = null;
  @observable usernameError: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  getManageFormData = async (handle: string) => {
    this.isLoading = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/handle/${handle}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      runInAction(() => {
        this.editCommunityData = response.data.data;
      });
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  updateField = async (updates: Partial<ManageCommunityFormValues>) => {
    if (!this.editCommunityData?.id) {
      throw new Error("Community data not found");
    }

    const updateData: Partial<ManageCommunityFormValues> = {
      ...updates,
      // Ensure categories is an array
      ...(updates.categories && {
        categories: Array.isArray(updates.categories)
          ? updates.categories
          : [updates.categories],
      }),
      // Merge social media with existing data
      ...(updates.socialMedia && {
        socialMedia: {
          ...this.editCommunityData.socialMedia,
          ...updates.socialMedia,
        },
      }),
    };

    const response = await communityApi.updateCommunity(
      this.editCommunityData.id,
      updateData
    );

    return response;
  };

  @action
  setIsUsernameAvailable = (available: boolean | null) => {
    this.isUsernameAvailable = available;
  };

  @action
  setUsernameError = (error: string | null) => {
    this.usernameError = error;
  };

  @action
  searchUsers = async (query: string) => {
    this.isSearchingUser = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/users/search?q=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      runInAction(() => {
        const filtered = response.data.filter(
          (user: any) =>
            !this.adminsList.some((admin) => admin.userId === user.userId)
        );
        this.searchResults = filtered;
      });
    } catch (error) {
      console.error("Error searching users:", error);
    } finally {
      runInAction(() => {
        this.isSearchingUser = false;
      });
    }
  };

  @action
  clearSearchResults = () => {
    this.searchResults = [];
    this.isSearchingUser = false;
  };

  @action
  getAdmins = async (communityId?: string) => {
    if (!communityId) {
      return;
    }
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${communityId}/admins`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      runInAction(() => {
        this.adminsList = response.data.items;
      });
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };

  @action
  deleteAdmin = async (userId: string) => {
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.put(
        `${apiBaseUrl}/communities/${this.editCommunityData?.id}/admins/${userId}`,
        {
          action: "remove",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status !== 200) throw new Error("Failed to delete admin");
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };

  @action
  addAdmin = async (userId: string) => {
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.put(
        `${apiBaseUrl}/communities/${this.editCommunityData?.id}/admins/${userId}`,
        {
          action: "add",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status !== 200) throw new Error("Failed to delete admin");
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };

  @action
  deleteCommunity = async () => {
    try {
      await apiClient.delete(
        `${apiBaseUrl}/communities/${this.editCommunityData?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error deleting community: ", error);
    } finally {
      runInAction(() => {
        this.editCommunityData = null;
      });
    }
  };
}
export const manageCommunityStore = new ManageCommunityStore();

export interface SearchResult {
  userId: string;
  handle: string;
  accountName: string;
  profilePicture?: string;
}

export interface AdminUser {
  isOwner: boolean;
  userId: string;
  handle: string;
  profilePicture: string;
  accountName: string;
}
