import React from "react";
import { Share2 } from "lucide-react";
import { Button } from "../shadcn-components/button";
import { cn } from "../lib/utils";

interface ShareButtonProps {
  title?: string;
  text?: string;
  url?: string;
  className?: string;
  variant?: "default" | "white";
  showText?: boolean;
  onShareComplete?: () => void;
  onShareError?: (error: Error) => void;
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  title = document.title,
  text = "Check this out!",
  url = window.location.href,
  className,
  variant = "white",
  showText = true,
  onShareComplete,
  onShareError,
}) => {
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title,
          text,
          url,
        });
        onShareComplete?.();
      } else {
        // Fallback: Copy to clipboard if native share fails
        await navigator.clipboard.writeText(url);
        alert("Link copied to clipboard");
        onShareComplete?.();
      }
    } catch (error) {
      console.error("Error sharing:", error);
      // Fallback to clipboard if share fails
      try {
        await navigator.clipboard.writeText(url);
        alert("Link copied to clipboard");
        onShareComplete?.();
      } catch (clipboardError) {
        console.error("Error copying to clipboard:", clipboardError);
        onShareError?.(
          clipboardError instanceof Error
            ? clipboardError
            : new Error("Failed to share")
        );
      }
    }
  };

  return (
    <Button
      onClick={handleShare}
      className={cn(
        "tw-flex tw-items-center tw-gap-2",
        variant === "white" && "tw-bg-white",
        className
      )}
    >
      <Share2
        className={cn("tw-w-5 tw-h-5", variant === "white" && "tw-text-black")}
      />
      {showText && (
        <span
          className={cn(
            "tw-font-semibold tw-text-sm",
            variant === "white" && "tw-text-black"
          )}
        >
          Share
        </span>
      )}
    </Button>
  );
};
