import { FC } from "react";
import imagePath from "../../static/empty-box.svg";
import { Button } from "../../shadcn-components/button";

export const ExploreCommunityEmptyState: FC = () => {
  return (
    <div className="tw-px-4">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <img
          src={imagePath}
          alt="Events planning illustration"
          className="tw-w-32 tw-h-32"
        />
        <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-5">
          <h2 className="tw-text-base tw-font-bold tw-text-center tw-mb-2">
            No upcoming events at the moment!{" "}
          </h2>
          <p className="tw-text-center tw-font-medium tw-text-xs tw-mb-6 tw-text-[#13131580]">
            It’s a bit lonely here! Why not build a space for people to connect?
            Start your community today
          </p>
          <Button className="!tw-rounded-[38px]">Build Community</Button>
        </div>
      </div>
    </div>
  );
};
