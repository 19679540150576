import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { Community } from "./community-store";
import axios from "axios";
import { EventData } from "../components/events/stores/event-store";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class CalendarStore {
  @observable eventData: EventData[] = [];
  @observable adminCommunities: Community[] = [];
  @observable isAdminCommunitiesLoading = false;
  @observable followingCommunities: Community[] = [];
  @observable isFollowingCommunitiesLoading = false;
  @observable registeredEventsOfUser: EventData[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  @action
  getEvents = () => {};

  @action
  getAdminCommunities = async () => {
    this.isAdminCommunitiesLoading = true;
    const response = await axios.get(`${apiBaseUrl}/communities?isAdmin=true`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.adminCommunities = response.data.items;
      this.isAdminCommunitiesLoading = false;
    });
  };
  @action
  getFollowingCommunities = async () => {
    this.isFollowingCommunitiesLoading = true;
    const response = await axios.get(
      `${apiBaseUrl}/communities?isFollowing=true`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.followingCommunities = response.data.items;
      this.isFollowingCommunitiesLoading = false;
    });
  };

  @action
  getEventsOfUser = async (filter: "past" | "future" | "all" = "future") => {
    const params = {
      dateRangeStart:
        filter === "past"
          ? new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString()
          : new Date(Date.now()).toISOString(),
      dateRangeEnd:
        filter === "past"
          ? new Date(Date.now()).toISOString()
          : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
      city: "delhi_ncr",
    };
    this.getFollowingCommunities();
    this.followingCommunities.forEach(async (fc) => {
      const queryString = new URLSearchParams(params).toString();
      try {
        const response = await axios.get(
          `${apiBaseUrl}/user/registered-events/${fc.id}?${queryString}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        runInAction(() => {
          this.registeredEventsOfUser = response.data.data;
        });
      } catch (error) {
        console.error("Failed to fetch events:", error);
        runInAction(() => {
          this.registeredEventsOfUser = [];
        });
      }
    });
  };
}

const calendarStore = new CalendarStore();
export default calendarStore;
