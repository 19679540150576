import { useState } from "react";
import { ImageCropper, ImageUploader } from "./image-uploader";

interface ImageUploadContainerProps {
  onImageChange: (file: File, previewUrl: string) => void;
  aspectRatio: number;
  previewUrl?: string | null;
  cropShape?: "rect" | "round";
  className?: string;
  buttonText?: string;
  height?: number;
  showDefault?: boolean;
}

export const ImageUploadContainer: React.FC<ImageUploadContainerProps> = ({
  onImageChange,
  aspectRatio,
  previewUrl,
  cropShape = "rect",
  className = "",
  buttonText,
  height,
  showDefault,
}) => {
  const [tempImage, setTempImage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageSelect = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setTempImage(reader.result as string);
      setIsModalOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCropConfirm = (croppedFile: File) => {
    if (tempImage && tempImage.startsWith("blob:")) {
      URL.revokeObjectURL(tempImage);
    }
    const newPreviewUrl = URL.createObjectURL(croppedFile);
    onImageChange(croppedFile, newPreviewUrl);
    setTempImage(null);
    setIsModalOpen(false);
  };

  const handleCropCancel = () => {
    if (tempImage && tempImage.startsWith("blob:")) {
      URL.revokeObjectURL(tempImage);
    }
    setTempImage(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={className}>
        <ImageUploader
          onImageSelect={handleImageSelect}
          previewUrl={previewUrl}
          className="tw-h-full"
          buttonText={buttonText}
          height={height}
          showDefault={showDefault}
        />
      </div>
      {isModalOpen && tempImage && (
        <div className="tw-fixed tw-inset-0 tw-bg-gray-500/75 tw-z-50 tw-flex tw-items-center tw-justify-center">
          <div className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-xl tw-w-[90%] md:tw-w-[60%]">
            <ImageCropper
              image={tempImage}
              aspectRatio={aspectRatio}
              onConfirm={handleCropConfirm}
              onCancel={handleCropCancel}
              containerClassName=""
              height={height}
            />
          </div>
        </div>
      )}
    </>
  );
};
