import { FC } from "react";
import { Dialog, DialogContent } from "../../../shadcn-components/dialog";
import { Button } from "../../../shadcn-components/button";

interface SuccessRegistrationDialogProps {
  /**
   * Controls the visibility of the dialog
   */
  open: boolean;
  /**
   * Callback function when the dialog is closed
   */
  onClose: () => void;
  /**
   * Callback function when the "View Ticket" button is clicked
   */
  onShowTicket: () => void;
}

const SuccessRegistrationDialog: FC<SuccessRegistrationDialogProps> = ({
  open,
  onClose,
  onShowTicket,
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-p-4">
          <div className="tw-w-64 tw-h-64 tw-relative tw-mb-6">
            <img
              src="/success-registration.png"
              alt="Success"
              className="tw-w-full tw-h-full tw-object-contain"
            />
          </div>

          <h2 className="tw-text-2xl tw-font-semibold tw-mb-2">
            Successfully Registered for the Event!
          </h2>

          <p className="tw-text-gray-600 tw-mb-8">
            You're in! We can't wait to see you at the event
          </p>

          <div className="tw-flex tw-gap-4 tw-w-full">
            <Button
              className="tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-flex-1"
              onClick={onShowTicket}
            >
              View Ticket
            </Button>

            <Button variant="outline" className="tw-flex-1" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessRegistrationDialog;
