import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../../shadcn-components/sheet";
import { Input } from "../../shadcn-components/input";
import { Button } from "../../shadcn-components/button";

interface DeleteCommunitySheetProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onDeleteClick: () => void;
}
export const DeleteCommunitySheet: FC<DeleteCommunitySheetProps> = observer(
  ({ open, onOpenChange, onDeleteClick }) => {
    const [deleteText, setDeleteText] = useState("");
    const handleDelete = () => {
      if (deleteText === "Delete") {
        onDeleteClick();
      } else {
        alert("Type Delete to confirm");
      }
    };
    return (
      <div>
        <Sheet open={open} onOpenChange={onOpenChange}>
          <SheetContent side="bottom" className="!tw-h-full">
            <SheetHeader>
              <SheetTitle className="!tw-text-left !tw-font-bold !tw-text-2xl tw-mb-2 !tw-h-3/4">
                Delete Community
              </SheetTitle>
              <SheetDescription className="!tw-text-left !tw-mb-4">
                Deleting your community will remove all of your information from
                our database. This cannot be undone.
              </SheetDescription>
            </SheetHeader>
            <div className="tw-grid tw-gap-4 tw-py-4">
              <div className="tw-text-sm">To Confirm this, type "Delete"</div>
              <Input
                value={deleteText}
                onChange={(e) => setDeleteText(e.target.value)}
              />
              <Button
                onClick={handleDelete}
                variant={"destructive"}
                className="!tw-rounded-[38px]"
              >
                Delete
              </Button>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    );
  }
);
