import { FC, useEffect } from "react";
import { Plus, ChevronRight } from "lucide-react";
import { Button } from "../../shadcn-components/button";
import { Card, CardContent, CardHeader } from "../../shadcn-components/card";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn-components/avatar";
import { observer } from "mobx-react-lite";
import { authStore } from "../../stores/auth-store";
import { getCommunityLogo, getUserImage } from "../../utils/image-utils";
import calendarStore from "../../stores/calendar-store";
import { useNavigate } from "react-router-dom";
import { EventCards } from "../calendar-events";
import EmptyState from "./user-events-empty";

export const Profile: FC = observer(() => {
  const { user } = authStore;
  const {
    getAdminCommunities,
    adminCommunities,
    getFollowingCommunities,
    followingCommunities,
    registeredEventsOfUser,
  } = calendarStore;

  useEffect(() => {
    const fetch = async () => {
      await getAdminCommunities();
      await getFollowingCommunities();
    };
    fetch();
  }, []);

  const formatDate = (utcString: string) => {
    const date = new Date(utcString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const weekday = date.toLocaleString("default", { weekday: "long" });

    return `Joined on ${day} ${month} ${year}, ${weekday}`;
  };

  const navigate = useNavigate();

  return (
    <div className="tw-w-full tw-max-w-md tw-mx-auto tw-bg-white tw-mt-8">
      <Card className="tw-mx-4 tw-overflow-hidden !tw-border-[#1313151A]">
        <CardHeader className="tw-bg-primary tw-pb-4 tw-pt-4 tw-px-4">
          <div className="tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-items-center tw-gap-3">
              <Avatar className="tw-h-13 tw-w-13">
                {user?.profilePicture && (
                  <AvatarImage
                    src={getUserImage(user?.profilePicture)}
                    alt="Profile"
                  />
                )}
                <AvatarFallback>{user?.accountName.charAt(0)}</AvatarFallback>
              </Avatar>
              <div className="tw-text-white">
                <h2 className="tw-font-bold tw-text-lg">{user?.accountName}</h2>
                <p className="tw-text-sm tw-opacity-90">{user?.email}</p>
              </div>
            </div>
            <Button
              variant="ghost"
              size="icon"
              className="tw-text-white"
              onClick={() => navigate("/me/edit")}
            >
              <ChevronRight className="tw-h-6 tw-w-6" />
            </Button>
          </div>
        </CardHeader>
        <CardContent className="tw-bg-gray-100 tw-px-4 !tw-py-0">
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-py-1">
            <span className="tw-text-sm">📅</span>
            {user?.createdOn && (
              <span className="tw-text-sm tw-text-gray-600">
                {formatDate(user?.createdOn)}
              </span>
            )}
          </div>
        </CardContent>
      </Card>

      <div className="tw-p-4">
        <h3 className="tw-text-lg tw-font-semibold tw-mb-3 !tw-text-left">
          My Communities
        </h3>
        <div className="tw-flex tw-gap-4 tw-items-start">
          {[...adminCommunities, ...followingCommunities].map((c) => (
            <div
              key={c.id}
              className="tw-text-center tw-flex tw-flex-col tw-items-center"
              onClick={() => navigate(`/${c.handle}`)}
            >
              <Avatar className="tw-h-[72px] tw-w-[72px] tw-mb-1">
                {c.profileImageUrl && (
                  <AvatarImage
                    src={getCommunityLogo(c.profileImageUrl)}
                    alt={c.title}
                  />
                )}
                <AvatarFallback>{c.title.charAt(0)}</AvatarFallback>
              </Avatar>
              <p className="tw-text-xs !tw-line-clamp-2 tw-font-medium !tw-truncate tw-text-left">
                {c.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      {registeredEventsOfUser.length > 0 ? (
        <div className="tw-p-4 tw-bg-gray-50">
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
            <h3 className="tw-text-lg tw-font-semibold">Past Events</h3>
            <Button
              variant="outline"
              className="tw-flex tw-items-center tw-gap-2"
              onClick={() => navigate("/community/create")}
            >
              <Plus className="tw-h-4 tw-w-4" />
              Create Community
            </Button>
          </div>
          <EventCards filter="past" />
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
});
