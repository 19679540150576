import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "../../shadcn-components/avatar";
import { Button } from "../../shadcn-components/button";
import { Input } from "../../shadcn-components/input";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../shadcn-components/sheet";
import { Search, Share2, UserMinus } from "lucide-react";
import { communityStore, FollowerUser } from "../../stores/community-store";
import { getUserImage } from "../../utils/image-utils";

interface CommunityFollowersSheetProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  communityId?: string;
}

export const CommunityFollowersSheet: FC<CommunityFollowersSheetProps> =
  observer(({ isOpen, onOpenChange, communityId }) => {
    const {
      followersByCommunityId,
      removeFollower,
      communityData,
      setProfileLoading,
    } = communityStore;

    const handleRemoveFollower = async (userId: string) => {
      if (!communityId) {
        return;
      }
      setProfileLoading(true);
      try {
        await removeFollower(communityId, userId);
      } catch (error) {
        console.error("Error removing follower community:", error);
      } finally {
        setProfileLoading(false);
      }
    };

    const followers = followersByCommunityId?.get(communityId || "") || [];
    return (
      <Sheet open={isOpen} onOpenChange={onOpenChange}>
        <SheetContent side="bottom" className="!tw-bg-white tw-h-full !tw-p-4">
          <SheetHeader>
            <SheetTitle className="!tw-mb-5">
              Followers ({communityData?.followerCount})
            </SheetTitle>
          </SheetHeader>
          <div className="tw-flex-col">
            <div className="tw-relative">
              <Input
                className="tw-mb-4 tw-pl-10 !tw-rounded-[38px]"
                placeholder="Search followers"
                type="search"
              />
              <Search className="tw-w-4 tw-h-4 tw-text-gray-400 tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2" />
            </div>
            <div className="tw-py-2">
              {followers.map((follower, index) => (
                <div
                  key={follower.userId}
                  className="tw-flex tw-items-center tw-justify-between tw-py-3 tw-px-4 first:tw-pt-0 last:tw-pb-0"
                >
                  <div className="tw-flex tw-items-center tw-gap-3">
                    <Avatar className="tw-w-10 tw-h-10 tw-rounded-full">
                      {follower.profilePicture && (
                        <AvatarImage
                          src={getUserImage(follower.profilePicture)}
                          alt={follower.accountName}
                          className="tw-rounded-full"
                        />
                      )}
                      <AvatarFallback>
                        {follower.accountName.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                    <div>
                      <span className="tw-font-medium">
                        {follower.accountName}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="tw-flex tw-justify-center">
              <Button
                className="tw-mt-4 !tw-rounded-lg !tw-border-[#13131533]"
                variant={"outline"}
              >
                <Share2 className="tw-w-4 tw-h-4 tw-mr-2" />
                Share link with friends
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    );
  });
