import { z } from "zod";
import { useEffect, useState, useRef, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "../../shadcn-components/textarea";
import { observer } from "mobx-react-lite";
import { getUserImage } from "../../utils/image-utils";
import profileSettingsStore from "../../stores/profile-settings-store";
import { SocialMediaInput } from "../communities/social-media-fields";
import signUpStore from "../../stores/signup-store";
import { ProfilePictureUpload } from "./profile-picture-upload";
import { Button } from "../../shadcn-components/button";
import { useNavigate } from "react-router-dom";
import PersonalDetailsSheet from "./personal-details";
import { ChevronRight, Eye, Pencil } from "lucide-react";
import { authStore } from "../../stores/auth-store";

// Schema definition
export const profileEditSchema = z.object({
  profilePicture: z.string().optional(),
  bio: z.string().max(500, "Bio must be less than 500 characters").optional(),
  igHandle: z
    .string()
    .regex(/^[a-zA-Z0-9._]+$/, "Invalid Instagram handle")
    .optional(),
});

export type ProfileEditFormValues = z.infer<typeof profileEditSchema>;

interface ProfileEditFormProps {
  initialData?: ProfileEditFormValues;
  onSubmit?: (data: ProfileEditFormValues) => Promise<void>;
}

export const ProfileEditForm: React.FC<ProfileEditFormProps> = observer(
  ({ initialData, onSubmit }) => {
    const { editProfileData, getEditProfileData } = profileSettingsStore;
    const [image, setImage] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [isSheetOpen, setIsSheetOpen] = useState(false);

    const methods = useForm<ProfileEditFormValues>({
      resolver: zodResolver(profileEditSchema),
      defaultValues: {
        bio: "",
        igHandle: "",
      },
    });
    const {
      register,
      handleSubmit,
      reset,
      watch,
      setValue,
      formState: { errors },
    } = methods;
    useEffect(() => {
      const fetch = async () => {
        await getEditProfileData();
      };
      fetch();
    }, [getEditProfileData]);
    useEffect(() => {
      if (editProfileData) {
        const imageUrl = editProfileData.profilePicture
          ? getUserImage(editProfileData.profilePicture)
          : null;
        setImage(imageUrl);

        reset({
          bio: editProfileData.bio || "",

          igHandle: editProfileData?.igHandle || "",
        });
      }
    }, [editProfileData, reset]);

    const debouncedSubmitProfile = useCallback(
      (data: Partial<ProfileEditFormValues>) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          // add profilePicture to payload if available
          if (signUpStore.uploadedImageKey) {
            signUpStore.submitProfile({
              ...data,
              profilePicture: signUpStore.uploadedImageKey,
            });
          } else {
            signUpStore.submitProfile(data);
          }
        }, 500);
      },
      []
    );

    useEffect(() => {
      const subscription = watch((value) => {
        if (value.bio) {
          debouncedSubmitProfile({ bio: value.bio });
        }
        if (value.igHandle) {
          debouncedSubmitProfile({ igHandle: value.igHandle });
        }
      });
      return () => subscription.unsubscribe();
    }, [watch, debouncedSubmitProfile]);

    const onSubmitForm = async (data: ProfileEditFormValues) => {
      setIsSubmitting(true);
      try {
        await onSubmit?.(data);
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmitForm)}
          className="tw-space-y-6 tw-w-full"
        >
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 !tw-px-4">
            <ProfilePictureUpload
              initialImage={image}
              onImageChange={(imageKey) => {
                setValue("profilePicture", imageKey);
                authStore.getUser();
              }}
              size={120}
              placeholderText="Edit Profile photo"
            />
          </div>

          <Button
            variant="link"
            className="tw-flex tw-justify-between tw-w-full tw-items-center !tw-p-0 !tw-px-4 !tw-text-black"
            onClick={() => setIsSheetOpen(true)}
          >
            <Eye className="tw-h-4 tw-w-4 tw-mr-2" />
            <span className="tw-text-left tw-font-medium tw-text-base">Personal Details</span>
            <ChevronRight size={16} className="tw-ml-auto" />
          </Button>
          <div className="tw-border-b-8 tw-border-gray-200 tw-w-full"></div>
          <div className="tw-space-y-2 !tw-px-4">
            <div className="tw-flex tw-items-center tw-w-full !tw-px-0 tw-pb-6">
              <Pencil className="tw-h-4 tw-w-4 tw-mr-2" />
              <span className="tw-text-left tw-text-base">Add Bio </span>
            </div>
            <Textarea
              {...register("bio")}
              placeholder="Write something about yourself..."
              className="tw-min-h-[100px]"
              style={{ marginTop: -10 }}
            />
            {errors.bio && (
              <p className="tw-text-red-500 tw-text-sm">{errors.bio.message}</p>
            )}
          </div>
          <div className="tw-border-b-8 tw-border-gray-200 tw-w-full"></div>
          <div className="tw-space-y-4 !tw-px-4">
            <h3 className="tw-text-base tw-font-medium tw-text-left">
              Social Links
            </h3>
            <div className="tw-space-y-3">
              {/* Instagram */}
              <SocialMediaInput
                platform="instagram"
                field={register("igHandle")}
                initialValue={initialData?.igHandle || ""}
                error={errors.igHandle?.message}
              />
            </div>
          </div>
          <PersonalDetailsSheet
            isOpen={isSheetOpen}
            onClose={() => setIsSheetOpen(!isSheetOpen)}
          />
        </form>
      </FormProvider>
    );
  }
);
