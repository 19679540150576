import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../shadcn-components/alert-dialog";
import { Button } from "../../../shadcn-components/button";
import imageUrl from "../../../static/cancelled.svg";

interface CancelledEventDialogProps {
  open: boolean;
  onClose: () => void;
}

const CancelledEventDialog: React.FC<CancelledEventDialogProps> = ({
  open,
  onClose,
}) => {
  const navigate = useNavigate();

  return (
    <AlertDialog open={open} onOpenChange={onClose}>
      <AlertDialogContent className="tw-rounded-lg tw-bg-white tw-max-w-[calc(100%-32px)] tw-mx-auto">
        <AlertDialogHeader>
          <div className="tw-flex tw-flex-col tw-items-center">
            <AlertDialogTitle className="tw-text-2xl tw-font-semibold">
              <img
                src={imageUrl}
                alt="Events planning illustration"
                className="tw-w-32 tw-h-32"
              />
              Event Cancelled!
            </AlertDialogTitle>

            <AlertDialogDescription className="tw-text-center tw-text-gray-500 tw-text-base">
              Unfortunately, this event has been cancelled by the host. We hope
              to see you at future events!
            </AlertDialogDescription>
          </div>
        </AlertDialogHeader>

        <div className="tw-mt-6 tw-items-center tw-flex tw-justify-center">
          <Button
            className=" tw-text-white tw-py-6 !tw-rounded-[38px]"
            onClick={() => {
              navigate("/explore");
              onClose();
            }}
          >
            Explore Events
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CancelledEventDialog;
