import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../shadcn-components/button";
import {
  ArrowLeft,
  ArrowRightFromLine,
  Check,
  MoreVertical,
  PhoneOutgoing,
  Share2,
} from "lucide-react";
import { Avatar, AvatarImage } from "@radix-ui/react-avatar";
import {
  CommunityFollowAction,
  communityStore,
} from "../../stores/community-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import signUpStore from "../../stores/signup-store";
import { AvatarCircles } from "./avatar-circles-with-followers";
import SocialMediaSection from "./social-media-links";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn-components/dropdown-menu";

import { AvatarFallback } from "../../shadcn-components/avatar";
import { cn } from "../../lib/utils";
import {
  getCommunityBanner,
  getCommunityLogo,
  getUserImage,
  ImageType,
} from "../../utils/image-utils";
import { getUsersPictures } from "../../utils/community-utils";
import whatsappIcon from "../../static/whatsapp-black.svg";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../shadcn-components/sheet";
import { EventsSection } from "./community-page-events";
import { CommunityFollowersSheet } from "./community-followers-sheet";
import { ShareButton } from "../share-button";

export const CommunityView: FC = observer(() => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isFollowersSheetOpen, setIsFollowersSheetOpen] = useState(false);
  const { communityHandle } = useParams<{ communityHandle: string }>();
  const {
    interestOptions,
    citiesOptions,
    getAllClientSideData: getAllOptionsForSignUp,
  } = signUpStore;
  const {
    setCurrentCommunityViewName,
    communityData,
    isProfileLoading,
    getCommunity,
    setProfileLoading,
    getAdmins,
    adminsList,
    getCommunityFollowers,
    followersByCommunityId,
  } = communityStore;
  useEffect(() => {
    const fetchCommunity = async () => {
      await getAllOptionsForSignUp();
      if (communityHandle) {
        await getCommunity(communityHandle);
      }
    };
    fetchCommunity();
  }, [communityHandle, getAllOptionsForSignUp, getCommunity]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (communityData?.id && !isProfileLoading) {
        await getCommunityFollowers([communityData.id]);
        await getAdmins(communityData.id);
      }
    };
    fetchEvents();
  }, [communityData, isProfileLoading, getAdmins, getCommunityFollowers]);

  useEffect(() => {
    communityStore.setCurrentCommunityViewName(communityHandle);
  }, [communityHandle, setCurrentCommunityViewName]);

  const navigate = useNavigate();

  const followOrUnfollow = useCallback(async () => {
    if (!communityData?.id) {
      return;
    }
    setProfileLoading(true);
    try {
      await communityStore.followOrUnfollow(
        communityData.isLoggedInUserFollower
          ? CommunityFollowAction.unFollow
          : CommunityFollowAction.follow
      );
    } catch (error) {
      console.error("Error following/unfollowing community:", error);
    }
  }, [
    communityData?.id,
    communityData?.isLoggedInUserFollower,
    setProfileLoading,
  ]);

  const handleFollowClick = async () => {
    if (communityData?.isUserNotLoggedIn) {
      navigate("/join");
    }
    if (communityData?.isLoggedInUserAdmin) {
      navigate(`/${communityData?.handle}/manage`);
      return;
    }
    await followOrUnfollow();
    if (communityData?.id) {
      await getCommunity(communityData?.handle);
    }
  };

  if (isProfileLoading || !communityData) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  const category =
    interestOptions &&
    interestOptions?.find((i) => i.id === communityData?.categories?.[0])
      ?.display_en;
  return (
    <div className="tw-w-full tw-bg-white">
      {/* Header Container */}
      <div className="tw-relative">
        {/* Banner Image */}
        <div className="tw-w-full tw-h-48">
          {communityData?.bannerImageUrls ? (
            <img
              src={getCommunityBanner(communityData.bannerImageUrls)}
              alt={`${communityData?.title} banner`}
              className="tw-w-full tw-h-full tw-object-cover"
            />
          ) : (
            <div className="tw-w-full tw-h-full tw-bg-gray-100 tw-flex tw-items-center tw-justify-center">
              <span className="tw-text-6xl tw-font-semibold tw-text-gray-400">
                {communityData?.title?.charAt(0).toUpperCase()}
              </span>
            </div>
          )}
        </div>
        {/* Floating Navigation Buttons */}
        <div className="tw-absolute tw-top-4 tw-left-0 tw-right-0 tw-px-4 tw-flex tw-justify-between tw-items-center">
          <button
            onClick={() => navigate(-1)}
            className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-full"
          >
            <ArrowLeft className="tw-w-6 tw-h-6" />
          </button>
          <div className="tw-flex tw-items-center tw-gap-2">
            <ShareButton
              variant="white"
              title="Check out this community"
              text="Join our amazing community!"
              url={window.location.href}
              className="tw-flex tw-items-center tw-bg-white tw-gap-2"
            />
            {communityData?.isLoggedInUserFollower && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white !tw-text-black !tw-p-0">
                    <MoreVertical className="tw-w-5 tw-h-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="end"
                  className="tw-w-48 tw-border-[#F1F5F9]"
                >
                  <DropdownMenuItem onClick={() => setIsSheetOpen(true)}>
                    <PhoneOutgoing />
                    Contact Admins
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    onClick={handleFollowClick}
                    className="tw-text-red-600"
                  >
                    <ArrowRightFromLine />
                    Unfollow
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
        {communityData?.isLoggedInUserFollower && (
          <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <SheetContent side="bottom" className="!tw-bg-white !tw-h-full">
              <SheetHeader>
                <SheetTitle className="!tw-mb-5 tw-text-left">
                  Contact
                </SheetTitle>
              </SheetHeader>
              <div className="tw-rounded-lg tw-border tw-border-gray-200 tw-py-6 tw-px-4">
                {adminsList.map((admin, index) => (
                  <div
                    key={admin.userId}
                    className={`tw-flex tw-items-center tw-justify-between tw-py-3 first:tw-pt-0 last:tw-pb-0  ${
                      index < adminsList.length - 1
                        ? "tw-border-b tw-border-gray-200"
                        : ""
                    }`}
                  >
                    <div className="tw-flex tw-items-center tw-gap-3">
                      <Avatar className="tw-w-10 tw-h-10 tw-rounded-full">
                        {admin.profilePicture && (
                          <AvatarImage
                            src={getUserImage(admin.profilePicture)}
                            alt={admin.accountName}
                            className="tw-rounded-full"
                          />
                        )}
                        <AvatarFallback>
                          {admin.accountName.charAt(0)}
                        </AvatarFallback>
                      </Avatar>
                      <span className="tw-font-medium">
                        {admin.accountName}
                      </span>
                    </div>
                    <Button className=" tw-py-2 tw-px-4" variant={"ghost"}>
                      <img
                        src={whatsappIcon}
                        className="tw-w-5 tw-h-5"
                        alt="WhatsApp"
                      />
                    </Button>
                  </div>
                ))}
              </div>
            </SheetContent>
          </Sheet>
        )}
        <div className="tw-px-4">
          {/* Logo and Join Button Row */}
          <div className="tw-relative -tw-mt-6 tw-flex tw-items-center tw-justify-between">
            <div className="tw-rounded-3xl tw-w-[88px] tw-h-[88px]">
              <Avatar className="tw-w-[88px] tw-h-[88px] tw-rounded-2xl">
                {communityData?.profileImageUrl && (
                  <AvatarImage
                    src={getCommunityLogo(
                      communityData.profileImageUrl,
                      ImageType.High
                    )}
                    alt={communityData?.title}
                    className="tw-rounded-2xl tw-border-4 tw-border-white"
                  />
                )}
                <AvatarFallback className="tw-bg-gray-200 tw-text-gray-700 !tw-w-[88px] !tw-h-[88px] tw-rounded-none">
                  {communityData?.title?.charAt(0)}
                </AvatarFallback>
              </Avatar>
            </div>
            <Button
              variant={
                communityData?.isLoggedInUserFollower ? "outline" : "default"
              }
              size="sm"
              className={cn(
                `!tw-rounded-[38px] !tw-p-4 tw-mt-8`,
                communityData?.isLoggedInUserFollower
                  ? "!tw-bg-[#13131533] !tw-border-none"
                  : ""
              )}
              onClick={handleFollowClick}
              disabled={communityData?.isLoggedInUserFollower}
            >
              {communityData?.isLoggedInUserFollower && (
                <Check className="tw-w-4 tw-h-4" />
              )}
              {communityData?.isLoggedInUserFollower
                ? "Joined"
                : communityData?.isLoggedInUserAdmin
                ? "Manage"
                : "Follow"}
            </Button>
          </div>

          {/* Handle and Category */}
          <div className="tw-mt-4 tw-flex tw-items-center tw-gap-2 tw-text-xs  tw-mb-2">
            <span>@{communityData?.handle}</span>
            {category && (
              <div>
                <span>•</span>
                <span>{category}</span>
              </div>
            )}
          </div>

          {/* Title */}
          <h1 className="tw-text-2xl tw-font-bold tw-mt-1 tw-text-left tw-mb-3">
            {communityData?.title}
          </h1>

          {/* Stats */}
          <div className="tw-flex tw-items-center tw-gap-2 tw-mt-1 tw-text-sm tw-text-gray-600">
            {communityData && (
              <AvatarCircles
                pictures={getUsersPictures(
                  communityData.id,
                  followersByCommunityId
                )}
                followerCount={communityData?.followerCount}
                onFollowersClick={() => setIsFollowersSheetOpen(true)}
                showFollowersText={false}
              />
            )}
            {communityData?.location && (
              <div>
                <span>•</span>
                <span>📍</span>
                <span>
                  {
                    citiesOptions.find((c) => c.id === communityData?.location)
                      ?.display_en
                  }
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Description */}
      {(communityData?.description || communityData?.socialMedia) && (
        <div className="tw-p-4">
          <p className="tw-text-base tw-text-grey tw-text-left">
            {communityData?.description}
          </p>
          {communityData?.socialMedia && (
            <SocialMediaSection socialMedia={communityData.socialMedia} />
          )}
        </div>
      )}

      {communityData && <EventsSection communityId={communityData?.id} />}
      {communityData.isLoggedInUserAdmin && (
        <CommunityFollowersSheet
          isOpen={isFollowersSheetOpen}
          onOpenChange={setIsFollowersSheetOpen}
          communityId={communityData?.id}
        />
      )}
    </div>
  );
});
