import { FC } from "react";
import imagePath from "../../static/empty-box.svg";

const EventsEmptyState: FC = () => {
  return (
    <div className="tw-px-4">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8">
        <img
          src={imagePath}
          alt="Events planning illustration"
          className="tw-w-32 tw-h-32 tw-mb-8"
        />
        <TicketBorder />
      </div>
    </div>
  );
};

const TicketBorder = () => {
  return (
    <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded-xl tw-border tw-border-gray-300 tw-p-5 tw-max-w-[400px] tw-overflow-hidden">
    <h2 className="tw-text-2xl tw-font-bold tw-text-center tw-mb-2">
      Events Under Planning <span role="img" aria-label="construction" className='tw-ml-1'>🚧</span>
    </h2>
    <p className="tw-text-center">Stay tuned! We'll share the dates soon.</p>
    <span className="tw-absolute tw-left-[-8px] tw-top-1/2 tw-w-4 tw-h-4 tw-rounded-full tw-border tw-border-gray-300 tw--translate-y-1/2 tw-bg-gray-100" aria-hidden="true"></span>
      <span className="tw-absolute tw-left-[-10px] tw-top-1/2 tw-w-[20px] tw-h-[16px] tw-bg-white tw--translate-y-1/2" aria-hidden="true"></span>
    <span className="tw-absolute tw-right-[-8px] tw-top-1/2 tw-w-4 tw-h-4 tw-rounded-full tw-border tw-border-gray-300 tw--translate-y-1/2 tw-bg-gray-100" aria-hidden="true"></span>
      <span className="tw-absolute tw-right-[-10px] tw-top-1/2 tw-w-[20px] tw-h-[16px] tw-bg-white tw--translate-y-1/2" aria-hidden="true"></span>
  </div>
  );
};

export default EventsEmptyState;
