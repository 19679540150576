import * as React from "react";
import { cn } from "../lib/utils";
import loader from "../static/loader-lottie.json";
import Lottie from "lottie-react";

const spinnerVariants = "tw-w-16 tw-h-16";

interface LoadingSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const LoadingSpinner = React.forwardRef<HTMLDivElement, LoadingSpinnerProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return (
      <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div ref={ref} className={cn(spinnerVariants, className)} {...rest}>
          <Lottie animationData={loader} loop={true} />
        </div>
      </div>
    );
  }
);

LoadingSpinner.displayName = "LoadingSpinner";

export { LoadingSpinner };
