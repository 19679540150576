import React, { FC, useEffect, useState } from "react";
import { communityStore } from "../../stores/community-store";
import { observer } from "mobx-react-lite";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn-components/tabs";
import { EventCard } from "./community-events-section";
import EventsEmptyState from "./events-empty-state";

interface EventsSectionProps {
  communityId: string;
}

export const EventsSection: FC<EventsSectionProps> = observer(
  ({ communityId }) => {
    const {
      upcomingEventsData,
      getUpcomingEventsOfCommunity,
      pastEventsData,
      getPastEventsOfCommunity,
      communityData,
    } = communityStore;
    useEffect(() => {
      const fetchEvents = async () => {
        await getUpcomingEventsOfCommunity(communityId);
        await getPastEventsOfCommunity(communityId);
      };
      fetchEvents();
    }, []);
    const [selectedTab, setSelectedTab] = useState<"upcoming" | "past">(
      "upcoming"
    );

    return (
      <div className="tw-px-4">
        <Tabs
          value={selectedTab}
          onValueChange={(value) =>
            setSelectedTab(value as "upcoming" | "past")
          }
          className="tw-w-full"
        >
          <div className="tw-flex tw-items-start tw-justify-between tw-mb-4">
            <h2 className="tw-text-2xl tw-font-semibold">Events</h2>
            <TabsList className="tw-bg-gray-100 tw-p-1 tw-rounded-full">
              <TabsTrigger
                value="upcoming"
                className="tw-rounded-full tw-px-4 tw-py-1 data-[state=active]:tw-bg-black data-[state=active]:tw-text-white"
              >
                Upcoming
              </TabsTrigger>
              <TabsTrigger
                value="past"
                className="tw-rounded-full tw-px-4 tw-py-1 data-[state=active]:tw-bg-black data-[state=active]:tw-text-white"
              >
                Past
              </TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value="upcoming" className="tw-w-full">
            <div>
              {Array.isArray(upcomingEventsData) &&
              upcomingEventsData.length > 0 ? (
                <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                  {upcomingEventsData.map((event) => (
                    <EventCard
                      key={event.eventId}
                      eventData={event}
                      communityLogo={communityData?.profileImageUrl}
                      communityTitle={communityData?.title}
                    />
                  ))}
                </div>
              ) : (
                <EventsEmptyState />
              )}
            </div>
          </TabsContent>

          <TabsContent value="past" className="tw-w-full">
            <div>
              {Array.isArray(pastEventsData) && pastEventsData.length > 0 ? (
                <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                  {pastEventsData.map((event) => (
                    <EventCard
                      key={event.eventId}
                      eventData={event}
                      communityLogo={communityData?.profileImageUrl}
                      communityTitle={communityData?.title}
                    />
                  ))}
                </div>
              ) : (
                <EventsEmptyState />
              )}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    );
  }
);
