import React, { useState } from "react";
import {
  HelpCircle,
  MessageSquare,
  Star,
  Info,
  FileText,
  Lock,
  LucideIcon,
  X,
  ChevronRight,
} from "lucide-react";
import { Button } from "../shadcn-components/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "../shadcn-components/sheet";
import { Input } from "../shadcn-components/input";
import { authStore } from "../stores/auth-store";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

interface SupportItem {
  icon: LucideIcon;
  text: string;
  onClick: () => void;
}

const SettingsPage: React.FC = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const navigate = useNavigate();
  const { logout } = authStore;
  const onLogOutClick = () => {
    logout();
    console.log('access:'+Cookies.get("accessToken"));
    console.log('access:'+Cookies.get("refreshToken"));
    Cookies.remove("accessToken", { path: "/" });
    Cookies.remove("refreshToken", { path: "/" });
    navigate("/");
  };

  const supportItems: SupportItem[] = [
    {
      icon: HelpCircle,
      text: "FAQs",
      onClick: () => console.log("FAQs clicked"),
    },
    {
      icon: MessageSquare,
      text: "Talk to Us",
      onClick: () => navigate("/talk-to-us"),
    },
    {
      icon: Star,
      text: "Rate Jackpass",
      onClick: () => console.log("Rate Jackpass clicked"),
    },
    {
      icon: Info,
      text: "About Jackpass",
      onClick: () => navigate("/about-us"),
    },
    {
      icon: FileText,
      text: "Terms of Service",
      onClick: () => navigate("/terms"),
    },
    {
      icon: Lock,
      text: "Privacy Policy",
      onClick: () => navigate("/privacy"),
    },
  ];

  const handleDelete = async () => {
    if (deleteConfirmation === "Delete") {
      setIsDeleteOpen(false);
      setDeleteConfirmation("");
      await authStore.deleteUser();
      navigate("/");
    }
  };

  return (
    <div className="tw-p-4 tw-max-w-md tw-mx-auto tw-space-y-4">
      <div className="tw-bg-white tw-rounded-2xl tw-border tw-border-gray-200">
        <h2 className="tw-text-base tw-font-semibold tw-p-4 tw-pb-2 !tw-text-left">
          Support
        </h2>
        <div className="tw-divide-y tw-divide-gray-200">
          {supportItems.map((item, index) => (
            <button
              key={index}
              className="tw-flex tw-items-center tw-justify-between tw-w-full tw-px-4 tw-py-3 hover:tw-bg-gray-50 focus:tw-outline-none"
              onClick={item.onClick}
            >
              <div className="tw-flex tw-items-center">
                <item.icon className="tw-h-5 tw-w-5 tw-mr-3 tw-text-gray-600" />
                <span className="tw-text-sm">{item.text}</span>
              </div>
              <ChevronRight className="tw-h-5 tw-w-5 tw-text-gray-400" />
            </button>
          ))}
        </div>
      </div>

      <div className="tw-mt-4 !tw-text-left tw-w-full">
        <div className="tw-border-t-[8px] tw-border-gray-200 tw--mx-4">
          <Button
            variant="link"
            className="tw-font-normal tw-pl-4 tw-mt-2 !tw-text-[#13131599]"
            onClick={onLogOutClick}
          >
            Log Out
          </Button>
        </div>
      </div>

      <div className="tw-mt-4 !tw-text-left tw-w-full">
        <div className="tw-border-t-[8px] tw-border-gray-200 tw--mx-4 !tw-pl-4">
          <Button
            variant="link"
            className="tw-font-normal !tw-px-0 tw-mt-2 !tw-text-[#E33629]"
            onClick={() => setIsDeleteOpen(true)}
          >
            Delete Account
          </Button>
          <p className="tw-text-xs tw-text-gray-500 tw-mt-1">
            Jackpass will permanently close your account and erase all of your
            personal information.
          </p>
        </div>
      </div>

      <Sheet open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
        <SheetContent side="bottom" className="tw-rounded-t-3xl !tw-h-full">
          <SheetHeader>
            <SheetTitle className="tw-text-xl">Delete Account</SheetTitle>
            <SheetDescription className="tw-text-sm tw-text-gray-600">
              Deleting your account will remove all of your information from our
              database. This cannot be undone.
            </SheetDescription>
          </SheetHeader>
          <div className="tw-py-6">
            <p className="tw-text-sm tw-mb-2">To Confirm this, type "Delete"</p>
            <Input
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
              placeholder="Type 'Delete'"
              className="tw-mb-4"
            />
            <Button
              variant="destructive"
              className="tw-w-full"
              onClick={handleDelete}
              disabled={deleteConfirmation !== "Delete"}
            >
              Delete
            </Button>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default SettingsPage;
