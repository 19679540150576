import { FC, useEffect } from "react";
import { Input } from "../../../shadcn-components/input";
import { Button } from "../../../shadcn-components/button";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../shadcn-components/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shadcn-components/select";
import { observer } from "mobx-react-lite";
import {
  createEventStore,
  eventFormSchema,
  EventFormValues,
  LocationValue,
} from "../stores/create-event-store";
import { toast } from "../../../hooks/use-toast";
import { DateTimePicker } from "../../../shadcn-components/datetime-picker";
import LocationField from "./location-field";
import { Textarea } from "../../../shadcn-components/textarea";
import { ImageUploadContainer } from "../../communities/image-upload-container";
import { useNavigate } from "react-router-dom";
import { MapView } from "./map-view";
import defaultImage from '../../../static/event-create-default.png';

export const EventCreationForm: FC = observer(() => {
  useEffect(() => {
    const fetch = async () => {
      try {
        await createEventStore.getAdminCommunities();
      } catch (error) {
        console.error("Error fetching communities:", error);
      }
    };
    fetch();
    return () => {
      if (createEventStore.imagePreview?.startsWith("blob:")) {
        URL.revokeObjectURL(createEventStore.imagePreview);
        createEventStore.setImagePreview(null);
      }
    };
  }, []);
  const { adminCommunities } = createEventStore;

  const form = useForm<EventFormValues>({
    resolver: zodResolver(eventFormSchema),
    defaultValues: {
      communityId: "",
      name: "",
      startDateTime: new Date().toISOString(),
      endDateTime: new Date(Date.now() + 3600000).toISOString(),
      city: "",
      location: {
        primaryAddress: "",
        secondaryAddress: "",
        latitude: 0,
        longitude: 0,
      },
      description: "",
      totalCapacity: "",
    },
  });

  const handleImageUpload = (file: File, previewUrl: string) => {
    createEventStore.setImageFile(file);
    createEventStore.setImagePreview(previewUrl);
  };

  const navigate = useNavigate();
  const onSubmit = async (values: EventFormValues) => {
    try {
      Object.entries(values).forEach(([key, value]) => {
        createEventStore.setFormField(key as keyof EventFormValues, value);
      });

      const createdId = await createEventStore.submitForm();
      if (!createdId) {
        throw new Error("Failed to create event");
      }
      navigate(`/e/${createdId}`);
    } catch (error) {
      toast({
        title: "Error",
        description: createEventStore.error || "Failed to create event",
        variant: "destructive",
      });
    }
  };

  const labelClasses = "tw-text-left tw-font-medium tw-text-base";
  const location = form.watch("location");
  const imagePreview = createEventStore.imagePreview;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="tw-space-y-6 tw-flex tw-flex-col tw-flex-1 tw-p-4"
      >
        <div className="tw-text-center tw-mb-2">
          <p className="tw-text-[#13131580] tw-font-medium tw-text-xs">
            Choose a banner image that represents your event
          </p>
        </div>

        <div className="tw-relative tw-mb-6">
          <div className="tw-rounded-lg tw-bg-gradient-to-b tw-from-[#EBF4F5] tw-to-[#B5C6E0]">
            <ImageUploadContainer
              onImageChange={handleImageUpload}
              aspectRatio={4 / 5}
              previewUrl={imagePreview || defaultImage}
              height={428}
              className="tw-h-full"
              buttonText="Add Event Banner"
              showDefault={!imagePreview}
            />
          </div>
        </div>

        <FormField
          control={form.control}
          name="communityId"
          render={({ field }) => (
            <FormItem className={labelClasses}>
              <FormLabel>Select Community</FormLabel>
              <Select
                value={field.value}
                onValueChange={(value) => {
                  field.onChange(value);
                  createEventStore.setFormField("communityId", value);
                  const selectedCommunity = adminCommunities.find(
                    (community) => community.id === value
                  );
                  if (selectedCommunity?.location) {
                    form.setValue("city", selectedCommunity.location, {
                      shouldValidate: true,
                    });
                    createEventStore.setFormField(
                      "city",
                      selectedCommunity.location
                    );
                  }
                }}
              >
                <SelectTrigger className="!tw-rounded-[34px] tw-border-1 !tw-border-[#13131533]">
                  <SelectValue placeholder="Select a community" />
                </SelectTrigger>
                <SelectContent>
                  {adminCommunities.map((community) => (
                    <SelectItem
                      key={community.id}
                      value={community.id.toString()}
                    >
                      {community.title}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className={labelClasses}>
              <FormLabel>Event Title</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter event title"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    createEventStore.setFormField("name", e.target.value);
                  }}
                  className="!tw-rounded-[34px] tw-border-1 !tw-border-[#13131533]"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="tw-space-y-4">
          <div className="tw-flex tw-gap-6">
            <div className="tw-relative">
              <div className="tw-flex tw-items-center tw-gap-2">
                <ChevronUp size={20} className="tw-text-gray-600" />
                <span className="tw-font-medium">Starts</span>
              </div>
              <div className="tw-w-0 tw-h-8 tw-border-l tw-border-dashed tw-border-[#13131580] tw-absolute tw-left-[10px] tw-top-[24px]"></div>
              <div className="tw-flex tw-items-center tw-gap-2 tw-mt-8">
                <ChevronDown size={20} className="tw-text-gray-600" />
                <span className="tw-font-medium">Ends</span>
              </div>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-8">
              <FormField
                control={form.control}
                name="startDateTime"
                render={({ field }) => (
                  <FormItem className="tw-m-0">
                    <FormControl>
                      <DateTimePicker
                        value={new Date(field.value)}
                        onChange={(date) => {
                          const isoDate = date?.toISOString();
                          field.onChange(isoDate);
                          createEventStore.setFormField(
                            "startDateTime",
                            isoDate || ""
                          );
                        }}
                        timePicker={{
                          hour: true,
                          minute: true,
                          second: false,
                        }}
                        use12HourFormat
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="endDateTime"
                render={({ field }) => (
                  <FormItem className="tw-m-0">
                    <FormControl>
                      <DateTimePicker
                        value={new Date(field.value)}
                        onChange={(date) => {
                          const isoDate = date?.toISOString();
                          field.onChange(isoDate);
                          createEventStore.setFormField(
                            "endDateTime",
                            isoDate || ""
                          );
                        }}
                        use12HourFormat
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
        <LocationField
          form={form}
          onLocationSelect={(location: LocationValue) => {
            form.setValue("location", location, {
              shouldValidate: true,
              shouldDirty: true,
            });
            createEventStore.setFormField("location", location);
            if (!form.getValues("city")) {
              const city = location.secondaryAddress.split(",")[0].trim();
              form.setValue("city", city, { shouldValidate: true });
              createEventStore.setFormField("city", city);
            }
          }}
        />

        {location?.latitude > 0 && location?.longitude > 0 && (
          <div className="tw-h-[150px] tw-w-full tw-rounded-lg tw-overflow-hidden">
            <MapView
              latitude={location.latitude}
              longitude={location.longitude}
            />
          </div>
        )}

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="tw-text-left tw-block">
                Add Description
              </FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Describe your event"
                  className="tw-resize-none tw-h-20"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    createEventStore.setFormField(
                      "description",
                      e.target.value
                    );
                  }}
                  disabled={createEventStore.isSubmitting}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="totalCapacity"
          render={({ field }) => (
            <FormItem>
              <div className="tw-flex tw-items-center tw-justify-between">
                <FormLabel className="tw-mb-0">Capacity</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="0"
                    className="tw-max-w-24"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      createEventStore.setFormField(
                        "totalCapacity",
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          className="tw-w-full tw-bg-blue-600 hover:tw-bg-blue-700"
          disabled={createEventStore.isSubmitting}
          loading={createEventStore.isSubmitting}
        >
          {createEventStore.isSubmitting ? "Creating Event..." : "Create Event"}
        </Button>
      </form>
    </Form>
  );
});