import { Share2 } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../shadcn-components/dialog";

interface EventTicketDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  name: string;
  communityName: string;
  registeredOn: string;
  eventDate: string;
  venue: string;
  ticketId: string;
  eventName: string;
}

const EventTicketDialog: React.FC<EventTicketDialogProps> = ({
  open,
  onOpenChange,
  name,
  communityName,
  registeredOn,
  eventDate,
  venue,
  ticketId,
  eventName,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="tw-max-w-[400px] tw-p-0 tw-rounded-lg tw-shadow-lg tw-bg-white">
        <DialogHeader className="tw-flex tw-justify-between tw-p-4">
          <DialogTitle className="tw-text-xl tw-font-semibold">
            Event Ticket
          </DialogTitle>
        </DialogHeader>
        <div className="tw-p-4 tw-bg-primary tw-text-white tw-rounded-b-lg tw-relative">
          <div className="tw-absolute tw-top-0 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-[25%] tw-bg-white tw-rounded-b-[150%] tw-w-[120px] tw-h-[40px]"></div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-4">
            <div>
              <div className="tw-text-sm tw-text-gray-100 tw-mb-1">Name</div>
              <div className="tw-text-lg">{name}</div>
            </div>
            <div className="tw-text-right">
              <div className="tw-text-sm tw-text-gray-100 tw-mb-1">
                Registered On
              </div>
              <div className="tw-text-lg">{registeredOn}</div>
            </div>
          </div>
          <div className="tw-mt-6">
            <div className="tw-text-sm tw-text-gray-100 tw-mb-1">Host</div>
            <div className="tw-text-lg">{communityName}</div>
          </div>
          <div className="tw-mt-4">
            <div className="tw-text-sm tw-text-gray-100 tw-mb-1">Event</div>
            <div className="tw-text-lg">{eventName}</div>
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-6">
            <div>
              <div className="tw-text-sm tw-text-gray-100 tw-mb-1">
                Event Date
              </div>
              <div className="tw-text-lg">{eventDate}</div>
              <div className="tw-text-lg">10:00 AM</div>
            </div>
            <div className="tw-text-right">
              <div className="tw-text-sm tw-text-gray-100 tw-mb-1">Venue</div>
              <div className="tw-text-lg tw-leading-tight">{venue}</div>
            </div>
          </div>
          <hr className="tw-my-6 tw-border-gray-400 tw-opacity-20" />
          <div className="tw-text-center tw-text-sm">
            Booking ID: {ticketId}
          </div>

          <div className="tw-flex tw-justify-center tw-mt-6">
            <button className="tw-text-white tw-flex tw-items-center tw-gap-2">
              <Share2 className="tw-h-6 tw-w-6" />
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EventTicketDialog;
