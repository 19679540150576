import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import Layout from "./components/layout";
import { CommunityView } from "./components/communities/community-profile-view";
import { EventCreationForm } from "./components/events/components/create-event-form";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { LoadingSpinner } from "./shadcn-components/loading-spinner";
import { EventDetailsView } from "./components/events/components/event-details-view";
import ManageEventForm from "./components/events/components/manage-event";
import { GuestList } from "./components/events/components/guests-list";
import { ExploreContent } from "./explore/components/explore-content";
import { Landing } from "./components/landing/landing";
import signupStore from "./stores/signup-store";
import TermsAndConditions from "./components/landing/terms-and-conditions";
import PrivacyPolicy from "./components/landing/privacy-policy";
import { ContactUs } from "./components/landing/contact-us";
import { LoginScreen } from "./components/user/join";
import { OTPVerificationScreen } from "./components/user/otp-verification";
import { ProfileForm } from "./components/user/onboarding-profile";
import { CalendarView } from "./components/calendar";
import { authStore } from "./stores/auth-store";
import CreateCommunityForm from "./components/communities/create-community-form";
import { AboutUsTemp } from "./components/landing2";
import { CommunityManagement } from "./components/communities/community-management";
import { ProfileEditForm } from "./components/user/edit-user-profile";
import SettingsPage from "./components/settings-page";
import { Profile } from "./components/user/user-profile";
import ContactOptions from "./components/talk-to-us";

export const PUBLIC_ROUTES = [
  "/about-us",
  "/terms",
  "/privacy",
  "/contact",
  "/",
  "/explore",
  "/calendar",
  "/c",
  "/search",
  "/e",
] as const;

export const PROTECTED_ROUTES = {
  exact: ["/c/new", "/me", "/e/new", "/settings", "/talk-to-us", "/calendar", "/me/edit"] as const,
  patterns: ["/*/manage", "/e/*/manage", "/e/*/checkin"] as const,
};

// Create a type from the exact routes array
type ExactProtectedRoute = (typeof PROTECTED_ROUTES.exact)[number];

export const AUTH_ROUTES = ["/join", "/verify", "/onboarding-profile"] as const;
const RequireAuth = observer(({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, initialized } = authStore;
  const location = useLocation();

  if (!initialized) {
    return <LoadingSpinner />;
  }

  const isAuthRoute = AUTH_ROUTES.some((route) => location.pathname === route);

  const isPublicRoute = PUBLIC_ROUTES.some((route) =>
    location.pathname.startsWith(route)
  );

  const isProtectedRoute =
    PROTECTED_ROUTES.exact.includes(location.pathname as ExactProtectedRoute) ||
    PROTECTED_ROUTES.patterns.some((pattern) => {
      const regexPattern = pattern.replace("*", "[^/]+");
      return new RegExp(`^${regexPattern}$`).test(location.pathname);
    });

  if (
    !isAuthenticated &&
    (isProtectedRoute || (!isPublicRoute && !isAuthRoute))
  ) {
    return <Navigate to="/join" state={{ from: location }} replace />;
  }

  if (isAuthenticated && isAuthRoute) {
    return <Navigate to="/explore" replace />;
  }

  return <>{children}</>;
});

const App = observer(() => {
  const { getAllClientSideData } = signupStore;

  useEffect(() => {
    const init = async () => {
      await authStore.initializeAuth();
      await getAllClientSideData();
    };
    init();
  }, []);

  useEffect(() => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const viewport = document.head.querySelector(
      'meta[name="viewport"]'
    ) as HTMLMetaElement;

    if (viewport) {
      if (iOS) {
        viewport.setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1"
        );
      } else {
        viewport.setAttribute("content", "width=device-width, initial-scale=1");
      }
    }
  }, []);
  const formRef = useRef<{ handleBack: () => void }>(null);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Public standalone routes */}
          {/* <Route path="/about-us" element={<AboutUs />} /> */}
          <Route path="/about-us" element={<AboutUsTemp />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/c/new" element={<CreateCommunityWrapper />} />

          {/* All other routes wrapped in Layout */}
          <Route
            element={
              <Layout onBack={() => formRef.current?.handleBack()}>
                <Outlet />
              </Layout>
            }
          >
            <Route
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              {/* Auth routes */}
              <Route path="/join" element={<LoginScreen />} />
              <Route path="/verify" element={<OTPVerificationScreen />} />
              <Route path="/onboarding-profile" element={<ProfileForm />} />

              {/* Public routes */}
              <Route path="/" element={<Landing />} />
              <Route path="/explore" element={<ExploreContent />} />
              <Route path="/calendar" element={<CalendarView />} />

              {/* Protected routes */}
              <Route path="/me" element={<Profile />} />
              <Route path="/me/edit" element={<ProfileEditForm />} />
              <Route path="/:communityHandle" element={<CommunityView />} />
              <Route
                path="/:communityHandle/manage"
                element={<CommunityManagement />}
              />
              <Route path="/e/new" element={<EventCreationForm />} />
              <Route path="/e/:eventId" element={<EventDetailsView />} />
              <Route path="/e/:eventId/manage" element={<ManageEventForm />} />
              <Route path="/e/:eventId/checkin" element={<GuestList />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/talk-to-us" element={<ContactOptions />} />

              {/* Fallback route */}
              <Route path="*" element={<Navigate to="/explore" replace />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
});
export default App;

const CreateCommunityWrapper = () => {
  // Pass the ref down to CreateCommunityForm
  const navigate = useNavigate();
  const formRef = useRef<{ handleBack: () => void }>(null);
  return (
    <Layout onBack={() => formRef.current?.handleBack()}>
      <CreateCommunityForm ref={formRef} onBack={() => navigate(-1)} />
    </Layout>
  );
};
