import { observer } from "mobx-react-lite";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn-components/avatar";
import { Badge } from "../../shadcn-components/badge";
import { getUserImage } from "../../utils/image-utils";

export interface PictureWithName {
  name: string;
  profilePicture?: string;
  id: string;
}
interface AvatarCirclesWithFollowersProps {
  pictures?: PictureWithName[];
  followerCount?: number;
  onFollowersClick: () => void;
  className?: string;
  showFollowersText?: boolean;
  showGoingText?: boolean;
}

export const AvatarCircles: React.FC<AvatarCirclesWithFollowersProps> =
  observer(
    ({
      pictures = [],
      followerCount = 0,
      className = "",
      showGoingText = false,
      onFollowersClick,
    }) => {
      const displayedAvatars = pictures.slice(0, 3);

      return (
        <div className={`tw-flex tw-items-center ${className}`}>
          {followerCount === 0 ? (
            <Badge className="!tw-bg-[#FFE291] !tw-text-black tw-rounded-sm">
              NEW
            </Badge>
          ) : (
            <div
              className="tw-flex tw--space-x-3 tw-items-center"
              onClick={onFollowersClick}
            >
              {displayedAvatars.map((p) => (
                <Avatar
                  key={p.id}
                  className="!tw-h-5 !tw-w-5 tw-border tw-border-white"
                >
                  {p.profilePicture && (
                    <AvatarImage
                      src={
                        p.profilePicture ? getUserImage(p.profilePicture) : ""
                      }
                      alt={`${p.name}`}
                    />
                  )}
                  {!p.profilePicture && (
                    <AvatarFallback className="tw-text-xs">
                      {p.name?.[0]}
                    </AvatarFallback>
                  )}
                </Avatar>
              ))}
              <span className="tw-text-sm tw-font-medium !tw-ml-1">
                {followerCount} {showGoingText ? "Going" : "Members"}
              </span>
            </div>
          )}
        </div>
      );
    }
  );
