import { FC, useEffect } from "react";
import imagePath from "../../static/empty-box.svg";
import { Button } from "../../shadcn-components/button";
import { exploreStore } from "../../stores/explore-store";

export const ExploreEventsEmptyState: FC = () => {
  const { adminCommunities, getAdminCommunities } = exploreStore;
  useEffect(() => {
    const fetch = async () => {
      await getAdminCommunities();
    };
    fetch();
  }, []);
  return (
    <div className="tw-px-4">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <img
          src={imagePath}
          alt="Events planning illustration"
          className="tw-w-32 tw-h-32"
        />
        <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-5">
          <h2 className="tw-text-base tw-font-bold tw-text-center tw-mb-2">
            No upcoming events at the moment!{" "}
          </h2>
          <p className="tw-text-center tw-font-medium tw-text-xs tw-mb-6 tw-text-[#13131580]">
            {adminCommunities.length > 0
              ? "Let’s fix that! Create an event and bring people together"
              : "It’s a bit quiet here right now! Stay tuned for upcoming events "}
          </p>
          {adminCommunities.length > 0 && (
            <Button className="!tw-rounded-[38px]">Create Event</Button>
          )}
        </div>
      </div>
    </div>
  );
};
