import { observer } from "mobx-react-lite";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shadcn-components/alert-dialog";

interface RemoveUserDialogProps {
  userName: string;
  onRemove: () => void;
  isOpen: boolean;
  onCancel: () => void;
}

export const RemoveUserDialog: React.FC<RemoveUserDialogProps> = observer(
  ({ userName, onRemove, isOpen, onCancel }) => {
    return (
      <AlertDialog open={isOpen}>
        <AlertDialogContent className="tw-max-w-[calc(100%-1rem)] !tw-bg-white tw-rounded-2xl">
          <AlertDialogHeader>
            <AlertDialogTitle className="tw-text-left">
              Remove {userName}
            </AlertDialogTitle>
            <AlertDialogDescription className="tw-text-left">
              Are you sure you want {userName} to leave this community? They
              will lose access to it.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="!tw-flex !tw-flex-row !tw-flex-nowrap tw-justify-end tw-items-baseline">
            <AlertDialogCancel
              onClick={onCancel}
              className="!tw-bg-primary tw-text-white !tw-rounded-[38px] tw-mr-4"
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              className="tw-text-red-500 !tw-border-none !tw-bg-white"
              onClick={onRemove}
            >
              Remove
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }
);
