import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../shadcn-components/alert-dialog";

interface CancelEventDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCancelEvent: () => void;
}

const CancelEventDialog: React.FC<CancelEventDialogProps> = ({
  open,
  onOpenChange,
  onCancelEvent,
}) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="tw-max-w-[calc(100%-1rem)] !tw-bg-white tw-rounded-2xl">
        <AlertDialogHeader>
          <AlertDialogTitle className="tw-text-xl tw-font-bold tw-text-left">
            Cancel Event
          </AlertDialogTitle>
          <AlertDialogDescription className="tw-text-base tw-text-gray-600 !tw-text-left">
            Are you sure you want to cancel this event? We will notify your
            guests
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="!tw-flex !tw-flex-row !tw-flex-nowrap tw-justify-end tw-items-baseline">
          <AlertDialogCancel className="tw-m-0 tw-bg-gray-100 tw-text-white !tw-rounded-[38px] !tw-bg-primary tw-mr-4">
            Go Back
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onCancelEvent}
            className="tw-bg-white tw-text-red-500 tw-border-0 hover:tw-bg-red-50"
          >
            Cancel Event
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CancelEventDialog;
