import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../shadcn-components/alert-dialog';

interface DiscardChangesDialogProps {
  open: boolean;
  onKeepEditing: () => void;
  onDiscard: () => void;
}

const DiscardChangesDialog: React.FC<DiscardChangesDialogProps> = ({
  open,
  onKeepEditing,
  onDiscard
}) => {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent className="tw-max-w-[calc(100%-1rem)] !tw-bg-white tw-rounded-2xl">
        <AlertDialogHeader>
          <AlertDialogTitle className='tw-text-left !tw-mb-2'>Discard Changes?</AlertDialogTitle>
          <AlertDialogDescription className='tw-text-left !tw-mb-2'>
            If you leave now, all the information you've entered will be lost. Do you still want to continue?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="!tw-flex !tw-flex-row !tw-flex-nowrap tw-justify-end tw-items-baseline">
          <AlertDialogAction
            onClick={onKeepEditing}
            className="tw-bg-indigo-600 hover:tw-bg-indigo-700 tw-mr-2"
          >
            Keep Editing
          </AlertDialogAction>
          <AlertDialogCancel
            onClick={onDiscard}
            className="tw-m-0 tw-text-red-500 hover:tw-text-red-600 hover:tw-bg-transparent tw-border-none"
          >
            Discard
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DiscardChangesDialog;