import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { DateTimePicker } from "../shadcn-components/datetime-picker";
import { Button } from "../shadcn-components/button";
import { ScrollArea, ScrollBar } from "../shadcn-components/scroll-area";
import { Plus, X } from "lucide-react";
import calendarStore from "../stores/calendar-store";
import { authStore } from "../stores/auth-store";
import { format } from "date-fns-tz";
import { EventCards } from "./calendar-events";

export const CalendarView: FC = observer(() => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedButton, setSelectedButton] = useState<string>("My Events");
  const { isAuthenticated } = authStore;
  const {
    adminCommunities,
    followingCommunities,
    getAdminCommunities,
    getFollowingCommunities,
    registeredEventsOfUser: eventsOfUser,
  } = calendarStore;

  useEffect(() => {
    const fetch = async () => {
      if (true) {
        await getAdminCommunities();
        // await getFollowingCommunities();
      }
    };
    fetch();
  }, []);

  const handleDayClick = (day: Date) => {
    setSelectedDate(day);
  };

  const allCommunities = [...adminCommunities, ...followingCommunities];

  const handleButtonClick = (eventName: string) => {
    setSelectedButton(selectedButton === eventName ? "" : eventName);
  };
  return (
    <div className="tw-w-full tw-max-w-sm tw-mx-auto tw-space-y-4">
      <div className="tw-flex tw-items-center tw-gap-3 tw-p-4 tw-rounded-lg">
        <div className="tw-whitespace-nowrap tw-text-base tw-font-bold tw-ml-6">
          Select Date
        </div>
        <DateTimePicker
          value={selectedDate}
          min={new Date()}
          onChange={(d) => d && handleDayClick(d)}
          hideTime
          className="!tw-rounded-[42px] tw-text-sm tw-font-medium"
          clearable
        />
      </div>

      <div className="tw-bg-gray-100 tw-px-4 tw-py-3 tw-space-y-4">
        <div className="tw-flex tw-gap-2 tw-w-full">
          <Button
            variant={selectedButton === "My Events" ? "default" : "outline"}
            onClick={() => handleButtonClick("My Events")}
            className={`!tw-h-[31px] tw-border !tw-border-[#131315] !tw-rounded-2xl tw-flex tw-items-center tw-gap-1 tw-whitespace-nowrap tw-shrink-0 !tw-py-[6px] !tw-px-4 ${
              selectedButton === "My Events"
                ? "tw-bg-blue-600 hover:tw-bg-blue-700"
                : "!tw-bg-white"
            }`}
          >
            {selectedButton === "My Events" ? (
              <X className="tw-w-4 tw-h-4" />
            ) : (
              <Plus className="tw-w-4 tw-h-4" />
            )}
            My Events
          </Button>

          <ScrollArea className="tw-w-full">
            <div className="tw-flex tw-gap-2">
              {allCommunities.map((c, index) => (
                <Button
                  key={index}
                  variant={selectedButton === c.id ? "default" : "outline"}
                  onClick={() => handleButtonClick(c.id)}
                  className={`!tw-h-[31px] tw-border !tw-border-[#131315] !tw-rounded-2xl tw-flex tw-items-center !tw-py-[6px] !tw-px-4 tw-gap-1 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-shrink-0 ${
                    selectedButton === c.id
                      ? "tw-bg-blue-600 hover:tw-bg-blue-700"
                      : "tw-bg-white"
                  }`}
                >
                  {selectedButton === c.id ? (
                    <X className="tw-w-4 tw-h-4" />
                  ) : (
                    <span className="tw-text-sm">+</span>
                  )}
                  {c.title}
                </Button>
              ))}
            </div>
            <ScrollBar orientation="horizontal" className="tw-hidden" />
          </ScrollArea>
        </div>

        <EventCards filter='future'/>
      </div>
    </div>
  );
});
