import { Plus } from "lucide-react";
import { Button } from "../../shadcn-components/button";
import { useNavigate } from "react-router-dom";
import image from "../../static/white-ticket.png";

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <div className="tw-p-4 tw-bg-gray-50">
      <div className="tw-flex tw-justify-center tw-mb-4">
        <Button
          className="tw-bg-blue-600 tw-text-white hover:tw-bg-blue-700 tw-flex tw-items-center tw-gap-2 tw-rounded-full"
          onClick={() => navigate("/community/create")}
        >
          <Plus className="tw-h-4 tw-w-4" />
          Create Community
        </Button>
      </div>

      <div className="tw-rounded-2xl tw-py-8 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-bg-gray-50 tw-shadow-sm tw-relative">
        <img
          src={image}
          alt="Ticket background"
          className="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-contain tw-opacity-100"
        />
        <div className="tw-relative tw-z-10 tw-flex tw-flex-col tw-items-center ">
          <span className="tw-text-2xl tw-mb-2">🤔</span>
          <h3 className="tw-text-base tw-font-bold tw-mb-1 tw-text-black ">
            No Events Till Now..
          </h3>
          <p className="tw-text-xs tw-text-black">
            Start your journey with fresh experiences
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
