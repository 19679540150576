import { QrCode } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../shadcn-components/card";
import { Button } from "../../../shadcn-components/button";
import { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { CancelRegistrationDialog } from "../stores/cancel-registration-dialog";
import EventTicketDialog from "./ticket";

interface TicketProps {
  onCancelClick: () => void;
  email?: string;
  name: string;
  communityName: string;
  registeredOn: string;
  eventDate: string;
  venue: string;
  ticketId: string;
  eventName: string;
  isTicketDialogOpen: boolean;
  onTicketDialogChange: (open: boolean) => void;
}

export const TicketDetailsSection: FC<TicketProps> = observer(
  ({
    onCancelClick,
    email,
    name,
    communityName,
    registeredOn,
    eventDate,
    venue,
    ticketId,
    eventName,
    isTicketDialogOpen,
    onTicketDialogChange,
  }) => {
    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const handleCancelConfirm = () => {
      onCancelClick();
      setShowCancelDialog(false);
    };
    return (
      <div className="tw-mb-8">
        <h2 className="tw-text-base tw-font-bold tw-mb-3 tw-text-left tw-ml-4">
          Ticket Details
        </h2>
        <Card className=" !tw-bg-[#F1F5F9] tw-border-none tw-text-left">
          <CardHeader className="!tw-pb-0 !tw-px-4 ">
            <CardTitle className="tw-text-base tw-font-semibold tw-mb-2">
              You're In ✅
            </CardTitle>
            {email && (
              <CardDescription className="tw-text-sm tw-font-medium">
                A confirmation email has been sent to {email}
              </CardDescription>
            )}
          </CardHeader>
          <CardContent className="!tw-p-4">
            <Button
              variant="default"
              onClick={() => onTicketDialogChange(true)}
              className="tw-w-full tw-text-white hover:tw-bg-blue-700 tw-mb-4 !tw-rounded-[38px] tw-py-3"
            >
              <QrCode className="tw-w-4 tw-h-4 tw-mr-2" />
              View Ticket
            </Button>
            <p className="tw-text-sm tw-text-gray-600">
              No longer able to attend? Notify the host by{" "}
              <button
                className="tw-text-red-500 hover:tw-underline tw-font-medium"
                onClick={() => setShowCancelDialog(true)}
              >
                Cancelling Your Registration
              </button>
            </p>
          </CardContent>
        </Card>
        <EventTicketDialog
          open={isTicketDialogOpen}
          onOpenChange={onTicketDialogChange}
          name={name}
          communityName={communityName}
          registeredOn={registeredOn}
          eventDate={eventDate}
          venue={venue}
          ticketId={ticketId}
          eventName={eventName}
        />
        <CancelRegistrationDialog
          open={showCancelDialog}
          onOpenChange={setShowCancelDialog}
          onConfirmCancel={handleCancelConfirm}
        />
      </div>
    );
  }
);
